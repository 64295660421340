<template>
  <div class="mobile">
    <div
      class="stream-chat-container mobile-chat-container col-md-12 col-lg-12 mb-5"
    >
      <Chat :type="chatType" :code="chatCode" :roomCode="chatRoomCode" />
    </div>
  </div>
</template>
<script>
import Chat from "@/components/chat/Chat";

export default {
  name: "MobileChat",
  components: {
    Chat,
  },
  data() {
    return {
      user: { firstname: "", lastname: "", email: "" },
      livestreamId: "",
      successful: false,
      message: "",
    };
  },
  async created() {
    if (
      !this.$route.query.firstname ||
      !this.$route.query.lastname ||
      !this.$route.query.email
    ) {
      this.$router.push({ name: "Home" });
      return;
    }

    this.user.firstname = atob(this.$route.query.firstname);
    this.user.lastname = atob(this.$route.query.lastname);
    this.user.email = atob(this.$route.query.email);
    this.livestreamId = atob(this.$route.query.livestreamId);

    this.handleRegister();
    await this.fetchLivestream();
  },
  methods: {
    handleRegister() {
      this.message = "";
      this.submitted = true;
      console.log(this.user);

      this.$store.dispatch("Auth/register", this.user).then(
        (data) => {
          console.log(data);
          this.message = data.message;
          this.successful = true;
        },
        (error) => {
          this.message = error;
          this.successful = false;
        }
      );
    },
    async fetchLivestream() {
      await this.$store.dispatch("Livestreams/fetchById", this.livestreamId);
    },
  },
  computed: {
    currentUser() {
      let currentUser = {
        email: "",
        username: "",
      };
      currentUser.username = this.user.firstname + " " + this.user.lastname;
      currentUser.email = this.user.email;

      return currentUser;
    },
    locale() {
      return this.$root.$i18n.locale;
    },
    livestream() {
      return this.$store.getters["Livestreams/livestream"];
    },
    chat() {
      return this.livestream?.chat;
    },
    chatType() {
      return this.chat?.type[this.locale];
    },
    chatCode() {
      return this.chat?.code[this.locale];
    },
    chatRoomCode() {
      return this.chat?.roomCode[this.locale];
    },
  },
};
</script>
<style scoped>
.mobile-chat-container {
  margin-top: 20px;
}

.mobile {
  overflow: auto !important;
  flex-grow: 1;
  height: 100%;
}

@media only screen and (max-width: 999px) {
  .stream-chat-container {
    min-height: 550px;
    margin-bottom: 20px;
    height: 100%;
  }
}
</style>